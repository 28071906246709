import BigNumber from "bignumber.js";
import { BIG_ONE, BIG_ZERO } from "utils/bigNumber";
import { filterFarmsByQuoteToken } from "utils/farmsPriceHelpers";
import { wplsUsdcFarmPid, mainTokenSymbol } from "config";
import addresses from "constants/addresses";

const getFarmFromTokenSymbol = (farms, tokenSymbol, preferredQuoteTokens) => {
  const farmsWithTokenSymbol = farms.filter(
    (farm) => farm.token.symbol === tokenSymbol
  );
  const filteredFarm = filterFarmsByQuoteToken(
    farmsWithTokenSymbol,
    preferredQuoteTokens
  );
  return filteredFarm;
};

const getFarmBaseTokenPrice = (
  farm,
  quoteTokenFarm,
  wplsPriceUsdt,
  snowPriceUsdc
) => {
  const hasTokenPriceVsQuote = Boolean(farm.tokenPriceVsQuote);
  if (["USDC", "MIM", "DAI"].includes(farm.quoteToken.symbol)) {
    return hasTokenPriceVsQuote
      ? new BigNumber(farm.tokenPriceVsQuote)
      : BIG_ZERO;
  }
  if (farm.quoteToken.symbol === "WPLS") {
    return hasTokenPriceVsQuote
      ? new BigNumber(wplsPriceUsdt).times(farm.tokenPriceVsQuote)
      : BIG_ZERO;
  }
  if (farm.quoteToken.symbol === "SNOW") {
    return hasTokenPriceVsQuote
      ? new BigNumber(snowPriceUsdc).times(farm.tokenPriceVsQuote)
      : BIG_ZERO;
  }

  // We can only calculate profits without a quoteTokenFarm for USDC/WPLS farms
  if (!quoteTokenFarm) {
    return BIG_ZERO;
  }

  // Possible alternative farm quoteTokens:
  // UST (i.e. MIR-UST), pBTC (i.e. PNT-pBTC), BTCB (i.e. bBADGER-BTCB), PLS (i.e. SUSHI-PLS)
  // If the farm's quote token isn't USDC or wWPLS, we then use the quote token, of the original farm's quote token
  // i.e. for farm PNT - pBTC we use the pBTC farm's quote token - WPLS, (pBTC - WPLS)
  // from the WPLS - pBTC price, we can calculate the PNT - USDC price
  if (quoteTokenFarm.quoteToken.symbol === "WPLS") {
    const quoteTokenInUsdc = wplsPriceUsdt.times(
      quoteTokenFarm.tokenPriceVsQuote
    );
    return hasTokenPriceVsQuote && quoteTokenInUsdc
      ? new BigNumber(farm.tokenPriceVsQuote).times(quoteTokenInUsdc)
      : BIG_ZERO;
  }

  if (["USDC", "MIM", "DAI"].includes(quoteTokenFarm.quoteToken.symbol)) {
    const quoteTokenInUsdc = quoteTokenFarm.tokenPriceVsQuote;
    return hasTokenPriceVsQuote && quoteTokenInUsdc
      ? new BigNumber(farm.tokenPriceVsQuote).times(quoteTokenInUsdc)
      : BIG_ZERO;
  }

  // Catch in case token does not have immediate or once-removed WMATIC/USDC quoteToken
  return BIG_ZERO;
};

const getFarmQuoteTokenPrice = (
  farm,
  quoteTokenFarm,
  wplsPriceUsdt,
  snowPriceUsdc
) => {
  if (["USDC", "MIM", "DAI"].includes(farm.quoteToken.symbol)) {
    return BIG_ONE;
  }
  if (farm.quoteToken.symbol === "SNOW") {
    return new BigNumber(snowPriceUsdc) || BIG_ZERO;
  }
  if (farm.quoteToken.symbol === "WPLS") {
    return new BigNumber(wplsPriceUsdt) || BIG_ZERO;
  }
  if (!quoteTokenFarm) {
    return BIG_ZERO;
  }

  if (quoteTokenFarm.quoteToken.symbol === "WPLS") {
    return quoteTokenFarm.tokenPriceVsQuote
      ? new BigNumber(wplsPriceUsdt).times(quoteTokenFarm.tokenPriceVsQuote)
      : BIG_ZERO;
  }

  return BIG_ZERO;
};
const fetchData = async () => {
  try {
    const returned = await (
      await fetch(
        `https://api.dexscreener.com/latest/dex/search?q=${mainTokenSymbol}`
      )
    ).json();

    if (returned && returned.pairs) {
      let data = returned.pairs[0];
      if (returned.pairs.length === 1) {
        data =
          returned.pairs[0].chainId === "pulsechain" &&
          returned.pairs[0].pairAddress === addresses.snowWplslp
            ? returned.pairs[0]
            : undefined;
      } else {
        data = returned.pairs.filter(
          (pair) =>
            pair.chainId === "pulsechain" &&
            pair.pairAddress === addresses.snowWplslp
        )[0];
      }

      return [data?.priceUsd, data?.priceUsd / data?.priceNative];
    }
  } catch (e) {
    console.log(e);
    return [0, 0];
  }
};
const fetchFarmsPrices = async (farms) => {
  const [snowPriceUsdc, wplsPriceUsdt] = await fetchData();
  const farmsWithPrices = farms.map((farm) => {
    const quoteTokenFarm = getFarmFromTokenSymbol(
      farms,
      farm.quoteToken.symbol
    );
    const baseTokenPrice = getFarmBaseTokenPrice(
      farm,
      quoteTokenFarm,
      wplsPriceUsdt,
      snowPriceUsdc
    );
    const quoteTokenPrice = getFarmQuoteTokenPrice(
      farm,
      quoteTokenFarm,
      wplsPriceUsdt,
      snowPriceUsdc
    );
    const token = { ...farm.token, usdcPrice: baseTokenPrice.toJSON() };
    const quoteToken = {
      ...farm.quoteToken,
      usdcPrice: quoteTokenPrice.toJSON(),
    };
    return { ...farm, token, quoteToken };
  });

  return farmsWithPrices;
};

export default fetchFarmsPrices;
