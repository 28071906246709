/* eslint-disable react/no-unused-prop-types */

import React from "react";
import { HelpIcon } from "uikit";

const Apr = ({ value, originalValue, pid }) => {
  return originalValue !== 0 ? (
    <div className="flex gap-3">
      <HelpIcon
        className="cursor-help"
        data-tooltip-id={"APR" + pid}
        data-tooltip-content="Annual Percentage Rate for SNOW farming"
      />
      <div className="box-text">{value || "0.00"} %</div>
    </div>
  ) : (
    <div className="flex gap-3">
      <HelpIcon
        className="cursor-help"
        data-tooltip-id={"APR" + pid}
        data-tooltip-content="Annual Percentage Rate for SNOW farming"
      />
      <div className="box-text">{originalValue || "0.00"} %</div>
    </div>
  );
};

export default Apr;
