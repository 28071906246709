import React, { useMemo, useEffect, useState } from "react";
import { useTranslation } from "context/Localization";
import getLiquidityUrlPathParts from "utils/getLiquidityUrlPathParts";
import { getScanAddressUrl } from "utils/getExplorerURL";
import {
  DepositLockDicountTag,
  NoFeesTag,
  SingleStakeTag,
  NFTStakeTag,
  CoreTag,
} from "components/Tags";
import { BASE_ADD_LIQUIDITY_URL, BASE_SWAP_URL } from "config";

import HarvestAction from "./HarvestAction";
import StakedAction from "./StakedAction";
import Apr from "../Apr";
import Multiplier from "../Multiplier";
import Liquidity from "../Liquidity";
import SnowCellLayout from "../SnowCellLayout";
import { formatAddress } from "utils/customHelpers";
import { FiExternalLink } from "react-icons/fi";

const ActionPanel = ({
  details,
  apr,
  multiplier,
  liquidity,
  userDataReady,
}) => {
  const [farm, setFarm] = useState(details);

  const { t } = useTranslation();
  const tokenOnly = farm.isTokenOnly;
  const nftOnly = farm.isNFTPool;
  const isActive = farm.multiplier !== "0X";
  const { quoteToken, token } = farm;
  const lpLabel =
    farm.lpSymbol && farm.lpSymbol.toUpperCase().replace("PANARB", "");
  const liquidityUrlPathParts = getLiquidityUrlPathParts({
    quoteTokenAddress: quoteToken.address,
    tokenAddress: token.address,
  });
  const lpAddress = farm.lpAddresses;
  const scan = useMemo(
    () => getScanAddressUrl(tokenOnly ? farm.token.address : lpAddress),
    [tokenOnly, lpAddress, farm.token.address]
  );
  const link = useMemo(
    () =>
      tokenOnly
        ? `${BASE_SWAP_URL}?outputCurrency=${farm.token.address}`
        : `${BASE_ADD_LIQUIDITY_URL}/${liquidityUrlPathParts}`,
    [tokenOnly, liquidityUrlPathParts, farm.token.address]
  );

  useEffect(() => {
    setFarm(details);
  }, [details]);

  return (
    <div className="">
      <SnowCellLayout label="">
        <a
          href={nftOnly ? "/nfts" : link}
          className="box-text flex gap-1"
          target="_blank"
          disabled={true}
          rel="noreferrer"
        >
          {t("Get %symbol%", { symbol: lpLabel })}
          <FiExternalLink className="my-auto text-sm" />
        </a>
      </SnowCellLayout>

      <SnowCellLayout label="">
        <a
          href={scan}
          className="box-text flex gap-2"
          target="_blank"
          rel="noreferrer"
        >
          {formatAddress(farm.lpAddresses, 6)}{" "}
          <FiExternalLink className="my-auto text-sm" />
        </a>
      </SnowCellLayout>

      <HarvestAction {...farm} userDataReady={userDataReady} />
      <StakedAction {...farm} userDataReady={userDataReady} />
    </div>
  );
};

export default ActionPanel;
