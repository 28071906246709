import React from "react";
import { useFarmUser } from "state/hooks";
import { useTranslation } from "context/Localization";
import { getBalanceNumber } from "utils/formatBalance";
import DepositFee from "./DepositFee";
import { RiEdgeNewFill } from "react-icons/ri";
import styled from "styled-components";
import { LinkExternal, Text } from "uikit";
import SnowCellLayout from "./SnowCellLayout";

import {
  MEMETag,
  NoFeesTag,
  SingleStakeTag,
  NFTStakeTag,
  CoreTag,
} from "components/Tags";

const TagsContainer = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 576px) {
    flex-direction: column;
    margin-top: 0;
    gap: 0.5rem;
  }
  > div {
    height: 24px;
    font-size: 18px;
    margin-right: 4px;
    border-radius: 5px;

    svg {
      width: 14px;
    }
  }
`;

const Farm = ({
  isTokenOnly,
  token,
  quoteToken,
  label,
  pid,
  depositFee,
  isNFTPool,
  hasDiscount,
}) => {
  const { stakedBalance } = useFarmUser(pid);
  const { t } = useTranslation();
  const rawStakedBalance = getBalanceNumber(stakedBalance);
  const noFees = parseFloat(depositFee) === 0;

  const handleRenderFarming = () => {
    if (rawStakedBalance) {
      return (
        <Text color="secondary" fontSize="12px">
          {t("Farming")}
        </Text>
      );
    }

    return null;
  };

  return (
    <div className="w-full">
      <SnowCellLayout label="Tags">
        <TagsContainer>
          {pid === 0 && <CoreTag />}
          {pid === 1 && <MEMETag />}
          {noFees && <NoFeesTag />}
          {pid === 2 && <SingleStakeTag />}
          {pid === 3 && <NFTStakeTag />}
        </TagsContainer>
      </SnowCellLayout>
      <div className="flex justify-center">
        {isTokenOnly ? (
          <div className="w-32 flex justify-end">
            <img src={token.logo} alt="" className="token" />
          </div>
        ) : (
          <div className="flex justify-end w-32">
            <img src={quoteToken.logo} alt="" className="token" />
            <img src={token.logo} alt="" className="token -ml-8" />
          </div>
        )}

        <RiEdgeNewFill className="text-white lg:mx-4 sm:mx-3 mx-6 my-auto text-2xl duration-200" />

        <div className="w-32">
          <img src="/assets/tokens/snow.png" alt="" className="token" />
        </div>
      </div>

      <div className="mt-4">
        {handleRenderFarming()}
        <p className="font-semibold text-sm lg:text-lg">{label}</p>

        <div className="flex justify-between mt-2">
          <div className="lg:text-[18px] text-[10px] text-gray-300">
            Deposit fee:
          </div>
          <div className="text-[12px]">
            <DepositFee
              depositFee={depositFee}
              isTokenOnly={isTokenOnly}
              hasDiscount={hasDiscount}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Farm;
